import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import racerspotter from "../../Assets/Projects/racerspotter.png";
import pdpn from "../../Assets/Projects/pdpn.png";
import louphub from "../../Assets/Projects/louphub.png";
import stadiumgo from "../../Assets/Projects/stadiumgo.png";
import URLTO from "../../Assets/Projects/URLTO.png";
import myspotter from "../../Assets/Projects/myspotter.png";
import pagfy from "../../Assets/Projects/pagfy.png";
import cs from "../../Assets/Projects/cs.png";
import ivan from "../../Assets/Projects/ivan.png";
import ir from "../../Assets/Projects/ir.png";
import leadsai from "../../Assets/Projects/leads.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={stadiumgo}
              isBlog={false}
              title="StadiumGO"
              description="O StadiumGO! é uma plataforma de investimentos financeiros no esporte. Oferecemos aos clubes, entidades esportivas e atletas serviços financeiros personalizados, diminuindo a distância entre o setor esportivo e o público que quer de fazer parte deste mercado econômico para realização de investimentos financeiros e obtenção de lucro. O StadiumGO! é a FINTECH pioneira que democratiza a captação de investimentos no esporte e torna o torcedor em investidor! Sem complexidade, oferecemos ao torcedor e publico em geral a oportunidade de participar das receitas de clubes ao comprarem os produtos que disponibilizamos em nossa plataforma. Gerando novas receitas ao esporte e riqueza pessoal ao público de forma segura, simples e facilitada."
              ghLink=""
              demoLink="https://stadiumgo.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={myspotter}
              isBlog={false}
              title="MySpotter"
              description="MySpotter® é um app de cronometragem em tempo real que provê informações dos seus tempos de volta e também de seus oponentes diretos enquanto você está na pista, pilotando. Permite que você melhore a cada volta e te ajuda a planejar sua corrida de forma estratégica."
              ghLink=""
              demoLink="https://myspotter.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={louphub}
              isBlog={false}
              title="LoupHub"
              description="A LOUP é a sua plataforma completa e amigável para criação de uma loja virtual versátil. De forma pioneira, conectamos o mundo cripto com o varejo. Oferecendo desde infoprodutos, bens de valor como eletrodomésticos, roupas, acessórios, a NFTs (Colecionáveis Digitais). Com o e-commerce gratuito da Loup a venda de produtos físicos, digitais ou criptos em um mesmo lugar é possível!"
              // ghLink=""
              demoLink="https://louphub.com.br/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={racerspotter}
              isBlog={false}
              title="RacerSpotter"
              description="Um aplicativo que permite acompanhar sua carreira no kart com estatísticas e tempos das últimas corridas em um só luga"
              // ghLink=""
              demoLink="https://racerspotter.app/"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leadsai}
              isBlog={false}
              title="LeadsAI"
              description="Encontre em TEMPO REAL dados cadastrais, contato e sociais, atualizados de empresas! Desenvolvido em Go, Node.js, PostgreSQL e AWS"
              // ghLink=""
              demoLink="https://leadsai.com.br/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={URLTO}
              isBlog={false}
              title="URL To"
              description="URL shortener for Lazy and Smart People"
              ghLink="https://github.com/alexmdcampo/urlto"
              demoLink="https://urlto-git-master-alexcampo.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pdpn}
              isBlog={false}
              title="PDPN"
              description="Site desenvolvido para equipe de Kart PDPN em WordPress + WooCommerce e integrações em Nodejs ** em lançamento"
              ghLink=""
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pagfy}
              isBlog={false}
              title="PagFy"
              description="Startup Acelerada pela Oxigênio, solução para pagamento touchless de estacionamentos e pedágios, solução desenvolvida em Ionic 4, node.js e MongoDB" 
              ghLink="http://pagfy.com.br/"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cs}
              isBlog={false}
              title="Cyberskies"
              description="We are an eSports game studio passionate about the cyberpunk universe. Our commitment is to create unique experiences that challenge players' imagination, create a solana blockchain game ecosystem" 
              ghLink="https://cys.gg/"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ivan}
              isBlog={false}
              title="Yvan Ótica"
              description="e-Commerce de Oculus desenvolvido em Ionic 4, node.js e MySql"
              // ghLink="https://cys.gg/"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ir}
              isBlog={false}
              title="iRacerStats for iRacing"
              description="O iRaceStats é uma plataforma que reúne informações de sua jornada no iRacing, gráficos de irating, sr, voltas completadas e muito mais desenvolvido em Ionic 4, node.js e MySql"
              // ghLink="https://cys.gg/"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
