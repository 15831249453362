import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Cal, { getCalApi } from "@calcom/embed-react";

function Agenda() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "30min" });
      cal("ui", {
        styles: { branding: { brandColor: "#2d1950" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <Container fluid className="agenda-section">
      <Row>
        <Col md={12} style={{ paddingTop: 50 }}>
          <div style={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
            <Cal
              namespace="30min"
              calLink="alex-campo/30min"
              style={{ width: "100%", height: "100%", overflow: "scroll" }}
              config={{ layout: "month_view" }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Agenda;
